.about {
  width: 90%;
  margin: 100px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-left {
  flex-basis: 40%;
  position: relative;
}
.about-right {
  flex-basis: 56%;
}

.about-img {
  width: 100%;
  border-radius: 10px;
}

.play-icon {
  width: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.about h1 {
  font-weight: 600;
  font-size: 16px;
  color: #212ea0;
}
.about h2 {
  font-size: 35px;
  color: #000f38;
  margin: 10px 0;
  max-width: 600px;
}
.about p {
  color: #676767;
  margin-bottom: 15px;
}
/* media query  */

@media (max-width: 800px) {
  .about {
    flex-direction: column;
  }
  .about-left {
    flex-basis: 100%;
    margin: 20px;
    order: 2;
  }
  .about-right {
    flex-basis: 100%;
  }
}
