.hero {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(rgba(8, 0, 58, 0.7), rgba(8, 0, 58, 0.7)),
    url("../../assets/hero.png");
  background-size: cover;
  background-position: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero-text {
  max-width: 800px;
  text-align: center;
}
.hero-text h2 {
  font-size: 60px;
  font-weight: 600;
}
.hero-text p {
  max-width: 700px;
  margin: 10px auto 20px;
  line-height: 1.4;
}

/* media Query  */

@media (max-width: 850px) {
  .hero-text h2 {
    font-size: 40px;
  }
}
@media (max-width: 650px) {
  .hero-text h2 {
    font-size: 30px;
    max-width: 400px;
    margin: auto;
  }
  .hero-text p {
    font-size: 14px;
    margin: 15px auto 30px;
  }
}
