.title {
  text-align: center;
  color: #212ea0;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 70px 0 30px;
}
.title h2 {
  font-size: 32px;
  color: #000f38;
  margin-top: 5px;
  text-transform: none;
}
