.programs {
  width: 90%;
  margin: 80px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.program {
  flex-basis: 31%;
  position: relative;
}
.program img {
  width: 100%;
  display: block;
  border-radius: 10px;
}
.caption {
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 15, 152, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  cursor: pointer;
  opacity: 0;
  padding-top: 70%;
  transition: 0.4s;
}

.caption img {
  width: 80px;
  margin-bottom: 10px;
}
.program:hover .caption {
  opacity: 1;
  padding-top: 0;
}

/* media Qurey  */

@media (max-width: 650px) {
  .programs {
    flex-direction: column;
  }
  .program {
    flex-basis: 100%;
    margin: 20px;
  }
}
